<template>
  <div class="p-lg-5 p-2 cards font-weight-bolder">
    <div class="row py-3">
      <div class="col benefit-title">
        <span>Time Expenditure</span>
      </div>
    </div>
    <div class="container text-center py-3">
      <button class="switch-left" v-bind:class="{'switch-left-selected': position === 'left'}"
              @click="position='left'">AILaw-Empowered
      </button>
      <button class="switch-right" v-bind:class="{'switch-right-selected': position === 'right'}"
              @click="position='right'">Traditional Law Firm
      </button>
    </div>
    <div class="row py-3">
      <div class="col">
        <DynamicBar :class="classes[0]" :percentage="perc[0]" is-horizontal="true">
          <div class="bar-text">
            <div v-if="position==='left'" class="display-3">40%</div>
            <div v-else class="display-3">10%</div>
            <div>Strategy</div>
          </div>
        </DynamicBar>
      </div>
    </div>
    <div class="row py-3 justify-content-between">
      <div class="col-3">
        <DynamicBar :class="classes[1]" :percentage="perc[1]">
          <div class="bar-text p-lg-3 pt-3 testsize">
            <img class="image-circle" :src="require('../../assets/symbol/circle-yellow.png')">
            <div class="py-3 intext">Letters</div>
            <div v-if="position==='left'" class="display-4">30%</div>
            <div v-else class="display-4">20%</div>
          </div>
        </DynamicBar>
      </div>

      <div class="col-3">
        <DynamicBar :class="classes[2]" :percentage="perc[2]">
          <div class="bar-text p-lg-3 pt-3 testsize">
            <img class="image-circle" :src="require('../../assets/symbol/circle-yellow.png')">
            <div class="py-3 intext">Research</div>
            <div v-if="position==='left'" class="display-4">15%</div>
            <div v-else class="display-4">10%</div>
          </div>
        </DynamicBar>
      </div>

      <div class="col-3">
        <DynamicBar :class="classes[3]" :percentage="perc[3]">
          <div class="bar-text p-lg-3 pt-3 testsize">
            <img class="image-circle" :src="require('../../assets/symbol/circle-blue.png')">
            <div class="py-3 intext">Forms</div>
            <div v-if="position==='left'" class="display-4">10%</div>
            <div v-else class="display-4">40%</div>
          </div>
        </DynamicBar>
      </div>

      <div class="col-3">
        <DynamicBar :class="classes[4]" :percentage="perc[4]">
          <div class="bar-text p-lg-3 pt-3 testsize">
            <img class="image-circle" :src="require('../../assets/symbol/circle-blue.png')">
            <div class="py-3 intext">Compilation</div>
            <div v-if="position==='left'" class="display-4">5%</div>
            <div v-else class="display-4">20%</div>
          </div>
        </DynamicBar>
      </div>
    </div>
    <div ref="watchdog"></div>
  </div>
</template>

<script>
  import DynamicBar from '../Block/DynamicBar'

  export default {
    name: "ImpactBenefit",
    components: {DynamicBar},
    data () {
      return {
        position: "left",
        active: false
      }
    },
    created () {
      var that = this
      window.addEventListener('scroll', function () {
        if (that.$refs.watchdog) {
          var bounding = that.$refs.watchdog.getBoundingClientRect();
          if (bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            that.active = true
          }
        }
      }, false);
    },
    computed: {
      perc () {
        if (this.active) {
          if (this.position === 'left') {
            return [80, 80, 30, 20, 10]
          } else {
            return [20, 30, 20, 80, 30]
          }
        }
        return [0, 0, 0, 0, 0]
      },
      classes () {
        return [
          {
            horizontal: true,
            bgray: !this.active || this.position === 'right',
            borange: this.active && this.position === 'left',
            'text-white': this.position === 'left',
          },
          {
            vertical: true,
            bgray: !this.active,
            borange: this.active && this.position === 'left',
            bpink: this.active && this.position === 'right',
            'text-white': this.position !== 'right',
          },
          {
            vertical: true,
            bgray: !this.active || this.position === 'right',
            borange: this.active && this.position === 'left',
          },
          {
            vertical: true,
            bgray: !this.active,
            bblue: this.active && this.position === 'left',
            bpink: this.active && this.position === 'right',
            'text-white': this.position === 'right',
          },
          {
            vertical: true,
            bgray: !this.active,
            bblue: this.active && this.position === 'left',
            bpink: this.active && this.position === 'right',
          }
        ]
      }
    }
  }
</script>

<style scoped lang="scss">
  @media screen and (max-width: 992px) {
    .display-4 {
      font-size: 1.5em;
    }

    .testsize {
      text-align: center;
    }

    .intext {
      font-size: 0.6rem;
    }
  }

  .bgray {
    background-color: #d6d6d6;

  }

  .bgray.vertical {
    background-image: linear-gradient(to top, #6f6f6f, #bebebe);
  }

  .bgray.horizontal {
    background-image: linear-gradient(to right, #6f6f6f, #bebebe);
  }

  .bblue {
    background-color: #DCE8F5;
  }

  .bblue.vertical {
    background-image: linear-gradient(to top, #0967D3, #58A6D6);
  }

  .bblue.horizontal {
    background-image: linear-gradient(to right, #0967D3, #58A6D6);
  }

  .borange {
    background-color: #F2E8D0;

  }

  .borange.vertical {
    background-image: linear-gradient(to top, #F76B1C, #F9A842);
  }

  .borange.horizontal {
    background-image: linear-gradient(to right, #F76B1C, #F9A842);
  }

  .bpink {
    background-color: #d6d6d6;

  }

  .bpink.vertical {
    background-image: linear-gradient(to top, #f08879, #f4ada2);
  }

  .bpink.horizontal {
    background-image: linear-gradient(to top, #f79679, #f7aaaa);
  }

  .horizontal {
    height: 16rem;
  }

  .vertical {
    height: 16rem;
  }

  .horizontal .bar-text {
    text-align: center;
    top: 30%;
    position: relative;
  }

  button {
    outline: none;
  }

  .switch-left {
    height: 55px;
    width: 250px;
    background-color: #FFFFFF;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: bolder;
    color: #848384;
    transition: 0.5s;
  }

  .switch-left-selected {
    height: 55px;
    width: 250px;
    background-color: #E5E4E5;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: bolder;
    color: #454445;
  }

  .switch-right {
    height: 55px;
    width: 250px;
    background-color: #FFFFFF;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: bolder;
    color: #848384;
    transition: 0.5s;
  }

  .switch-right-selected {
    height: 55px;
    width: 250px;
    background-color: #E5E4E5;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: bolder;
    color: #454445;
  }
  .bgray .image-circle,
  .bpink .image-circle{
    filter: grayscale(100%) contrast(0%);
  }

  .switch-left:focus, .switch-right:focus {
    outline: none;
  }

  .benefit-title {
    text-align: center;
    font-weight: bolder;
    font-size: 24px;
  }
</style>
