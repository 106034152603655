<template>
  <div class="row justify-content-center bg-white p-3">
    <div class="col-md-5 m-2 col-12">
      <slot name="left"></slot>
    </div>
    <div class="col-md-5 m-2 col-12">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Present",
  }
</script>

<style scoped lang="scss">
</style>
