<template>
  <div>
    <div>
      <div class="container px-md-0">
        <div class="row">
          <Grid class="col-md-4 col-6" title="Cloud Document Management" :img="require('../../assets/symbol/communication.png')"
                hideDetail="true">
            <span class="grid-content-home">Storing documents in the encrypted cloud ensures security and allows online reviewing, enabling collaboration while reducing offline file management.</span>
          </Grid>
          <Grid class="col-md-4 col-6" title="Form Autofill"
                :img="require('../../assets/symbol/quality.png')" hideDetail="true">
            <span class="grid-content-home">Using simple yet carefully curated worksheets to collect client information, automatically complete legal forms.</span>
          </Grid>
          <Grid class="col-md-4 col-6" title="Simplified Communication" :img="require('../../assets/symbol/efficiency.png')"
                hideDetail="true">
            <span class="grid-content-home">Save attorneys 35% of their time by handling all case-related communication and document revision through the platform using its live commenting and feedback system.</span>
          </Grid>
          <Grid class="col-md-4 col-6" title="Transparent Progress Tracking"
                :img="require('../../assets/symbol/communication.png')" hideDetail="true">
            <span class="grid-content-home">Sync and notify clients of all key milestones automatically. Make case status accessible to each client, keeping everyone informed at all times.</span>
          </Grid>
          <Grid class="col-md-4 col-6" title="Smart Compilation" :img="require('../../assets/symbol/quality.png')"
                hideDetail="true">
            <span class="grid-content-home">Compile documents with one click: drag and drop files into the designated printing order and convert everything into one printer-ready PDF file.</span>
          </Grid>
          <Grid class="col-md-4 col-6" title="Intuitive Case Management" :img="require('../../assets/symbol/efficiency.png')"
                hideDetail="true">
            <span class="grid-content-home">View key case information on the dashboard's custom control sheet, engineered for both ease of use and full customization.</span>
          </Grid>
        </div>
      </div>


      <div class="container">
        <ImpactBenefit/>
      </div>

      <!--<Board class="mx-0 background-image-home" :title="'Watch AIlaw In Action'"-->
      <!--:img="require('../../assets/nxt/laptop.png')" :imgLocation="'left'" :atBottomHome="true">-->
      <!--See how your application process can be improve with AIlaw's revolutionary technologies-->
      <!--</Board>-->
      <div class="py-5"></div>
      <h2 class="text-center font-weight-bolder">Trusted by the world's best teams</h2>
      <div class="container margin-testimonial testdisplay">
        <Testimonial>
          <Comment slot="left" :img="require('../../assets/partner/yclaw.png')" title="YC Law ">
            After integrating our company’s cases into AILaw’s software, I’ve noticed a drastic improvement in
            efficiency. Clients are happy to forgo long email chains, clerks here save time previously spent manually
            filling in forms, and attorneys can take on more cases. I couldn’t be happier with our experience with
            AILaw.
          </Comment>
          <Comment slot="middle" :img="require('../../assets/partner/deheng.png')" title="DeHeng Law Offices" >
            Our experience with AILaw support team was extraordinary. We are a lot more efficient and have better
            transparency with clients because of AILaw's software. Our team members absolutely love the Worksheets
            and prepare all cases and forms in AILaw.
          </Comment>
          <!--<Comment slot="middle" :img="require('../../assets/partner/lydlaw.png')" title="LYD Law ">-->
            <!--Transparency, Automation, Cost-Efficiency. That’s exactly what AILaw has provided us with: a platform not-->
            <!--only easing the communication between clients and attorneys but simplifying this procedure for all parties-->
            <!--involved. This is a service that embodies the saying: “Money Well Spent”.-->
          <!--</Comment>-->
          <Comment slot="right" :img="require('../../assets/partner/sunwiselaw.png')" title="Sunwise Law" >
            Document submission and compilation should be intuitive, not just for the sake of clients but for law firms
            as well. One of our largest issues prior to utilizing AILaw’s platform was organizing documents for
            submissions, a problem perfectly addressed by the automation technology present here. We’ve saved countless
            hours, and I look forward to continuing our partnership.
          </Comment>
        </Testimonial>
      </div>

      <div id="carouselExampleControls" class="container carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
              <Comment slot="left" :img="require('../../assets/partner/yclaw.png')" title="YC Law ">
              After integrating our company’s cases into AILaw’s software, I’ve noticed a drastic improvement in
              efficiency. Clients are happy to forgo long email chains, clerks here save time previously spent manually
              filling in forms, and attorneys can take on more cases. I couldn’t be happier with our experience with
              AILaw.
            </Comment>
          </div>
          <div class="carousel-item">
            <!--<Comment slot="middle" :img="require('../../assets/partner/lydlaw.png')" title="LYD Law ">-->
            <!--Transparency, Automation, Cost-Efficiency. That’s exactly what AILaw has provided us with: a platform not-->
            <!--only easing the communication between clients and attorneys but simplifying this procedure for all parties-->
            <!--involved. This is a service that embodies the saying: “Money Well Spent”.-->
            <!--</Comment>-->
            <Comment slot="middle" :img="require('../../assets/partner/deheng.png')" title="DeHeng Law Offices" >
            Our experience with AILaw support team was extraordinary. We are a lot more efficient and have better
            transparency with clients because of AILaw's software. Our team members absolutely love the Worksheets
            and prepare all cases and forms in AILaw.
            </Comment>
          </div>

            <div class="carousel-item">
            <Comment slot="right" :img="require('../../assets/partner/sunwiselaw.png')" title="Sunwise Law" >
            Document submission and compilation should be intuitive, not just for the sake of clients but for law firms
            as well. One of our largest issues prior to utilizing AILaw’s platform was organizing documents for
            submissions, a problem perfectly addressed by the automation technology present here. We’ve saved countless
            hours, and I look forward to continuing our partnership.
          </Comment>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import Grid from '../Block/Grid';
  import Testimonial from '../Block/Testimonial';
  import Comment from '../Block/Comment'
  import ImpactBenefit from './ImpactBenefit'

  export default {
    components: {ImpactBenefit, Comment, Testimonial, Grid}
  }
</script>

<style scoped>
  .slide {
    display: none;
  }

  .grid-content-home {
    font-size: 17px;
  }

  .text-title {
    margin-top: 150px;
    font-weight: 700;
    color: #4A4A4A;
  }

  .text-margin {
    margin-top: 100px;
    margin-bottom: 100px;
  }

   @media screen and (max-width: 1023px) {
        .testdisplay {
            display: none;
        }

        .grid-content-home {
          font-size: 0.75rem;
        }

        .slide {
          display: inline-block;
        }
    }

  .background-image-home {
    color: white;
    background-color: #F0F0F0;
    background-image: url('../../assets/nxt/watch_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .mid-description {
    font-size: 40px;
    color: #4A4A4A;
    font-weight: bold;
  }
</style>
