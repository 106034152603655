<template>
  <div class="bar" :style="styleObject">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "HorizontalBar",
    props: ["isHorizontal", "percentage"],
    computed: {
      styleObject () {
        return {
          'background-size': this.isHorizontal ? `${this.percentage}% 100%` : `100% ${this.percentage}%`
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .bar {
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: left bottom;
    transition: background-size 1s;
  }

</style>
