<template>
  <div class="bg">
    <div class="py-md-5 py-3 text-white hero row container justify-content-between mx-auto mtopbar">
      <div class="pl-md-5 col-lg-1 scroll">
        <img class="d-block ml-auto" src='../../assets/symbol/scroll.png'>
        <span class="py-2 scrolltext">Scroll Down</span>
      </div>
      <Splash class="col-lg-11">
        <div slot="slogan">Next Generation Legal Software</div>
        <div slot="slogan3">An easy-to-use platform integrating case management, form autofilling, lawyer and client portals, and much more
        </div>
        <div slot="addon">
          <DemoButton/>
        </div>
        <div slot="banner"><img class="img-fluid imagesize" src="@/assets/nxt/watch_mac.png"></div>
      </Splash>
    </div>
  </div>
</template>

<script>
  import Splash from '../Block/Splash'
  import DemoButton from '../Block/DemoButton'

  export default {
    components: {Splash, DemoButton}
  }
</script>

<style scoped>
  .bg {
    background-image: url("../../assets/nxt/hero_bg.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-top: -4.5rem;
    padding-top: 10rem;
    padding-bottom: 20rem;
  }

  .scroll {
    position: relative;
    top: 10rem;
  }

  @media only screen and (max-width: 1023px) {
    .bg {
      background-image: url("../../assets/nxt/hero_bg.png");
      background-repeat: no-repeat;
      background-size: 135% 90%;
      margin-top: -4.5rem;
      padding-top: 3.5rem;
      padding-bottom: 15rem;
    }

    .mtopbar {
      margin-top: 1.0rem;
    }

    .scroll {
      display: none;
    }

    .imagesize{
      margin-top: 5%;
      margin-left: 5%;
    }

  }

  
</style>
