<template>
  <div
    class="row align-items-center justify-content-lg-around justify-content-center"
  >
    <div
      class="text-left col-12"
      :class="size === 'large' ? 'col-lg-12' : 'col-lg-6'"
    >
      <div class="font-weight-bolder py-md-3 pr-lg-2 slogan">
        <slot name="slogan"></slot>
        <div class="slogan2">
          <slot name="slogan2"></slot>
        </div>
      </div>
      <h4 class="py-3 slogan-text slogan-main">
        <slot name="slogan3"></slot>
      </h4>
      <slot name="addon"></slot>
    </div>
    <div class="col-lg-6 col-10 banner">
      <slot name="banner"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Splash',
  props: ['size'],
}
</script>

<style scoped lang="scss">
.slogan {
  font-size: 2.5rem;
}

.slogan2 {
  font-size: 2rem;
}

@keyframes popin {
  0% {
    opacity: 0.2;
    position: relative;
    top: 10px;
  }
  100% {
    opacity: 1;
    position: relative;
    top: 0;
  }
}

@keyframes popin2 {
  0% {
    opacity: 0.2;
    position: relative;
    top: -10px;
  }
  100% {
    opacity: 1;
    position: relative;
    top: 0;
  }
}

.banner {
  animation-name: popin2;
  animation-duration: 1s;
}
.slogan {
  animation-name: popin;
  animation-duration: 1s;
}

@keyframes popin-slow {
  0% {
    opacity: 0;
    position: relative;
    top: 10px;
  }
  50% {
    opacity: 0;
    position: relative;
    top: 10px;
  }
  100% {
    opacity: 1;
    position: relative;
    top: 0;
  }
}

.slogan-text {
  animation-name: popin-slow;
  animation-duration: 2s;
}

.slogan-text {
  font-size: 1rem;
  font-style: italic;
  font-weight: lighter;
}

@media screen and (max-width: 576px) {
  .slogan {
    font-size: 1.5rem;
    margin-left: 10%;
    text-align: center;
    padding-top: 1rem;
  }

  .slogan-text {
    font-size: 1.125rem;
    margin-left: 10%;
    text-align: center;
  }
}

@include media-breakpoint-up(lg) {
  .slogan {
    font-size: 3rem;
  }

  .slogan2 {
    font-size: 3.125rem;
  }

  .slogan-text {
    font-size: 1.25rem;
  }

  .slogan-main {
    line-height: 1.75rem;
  }
}
</style>
