<template>
  <div>
    <HeroMain/>
    <div class="container px-md-0 shift-top">
      <Present class="py-4">
        <div slot="left">
          <h2 class="font-weight-bold py-4">A smarter way to:</h2>
          <div class="font-weight-lighter py-3">
            <h5>Automatically generate forms</h5>
            <h5>Simplify communications through portals</h5>
            <h5>Synchronize with case checklists</h5>
            <h5>Provide customized caseload reports</h5>
            <h5>Streamline processing through status tracking</h5>
          </div>
        </div>
        <div class="img-fluid" slot="right">
          <video autoplay loop preload muted poster="../assets/nxt/poster.png" playsinline webkit-playsinline
                 class="mw-100" @click="play($event)" id="playVideo">
            <source src="../assets/video/guide.mp4" type="video/mp4">
            <source src="../assets/video/guide.webm" type="video/webm">
            Your browser does not support the HTML5 video.
          </video>
        </div>
      </Present>
    </div>
    <HeroContent/>
    <div class="foot-img w-100"/>
  </div>
</template>

<script>
  import HeroMain from './Section/HeroMain'
  import HeroContent from './Section/HeroContent'
  import Present from "./Block/Present";

  export default {
    components: {Present, HeroMain, HeroContent}
  }
</script>

<style scoped>
  .shift-top {
    margin-top: -10rem;
  }

  .foot-img {
    background-image: url("../assets/nxt/footer.png");
    min-height: 10rem;
    background-size: 100% auto;
    background-repeat: no-repeat
  }
</style>
