<template>
  <div>
    <a target="_blank" class="btn text-white py-md-3 px-md-5 textresize" href="https://www.immigrationlaw.ai/activity/ailaw/demo">
      Schedule Demo Now
    </a>
  </div>
</template>

<script>
  export default {
    name: "DemoButton"
  }
</script>

<style scoped>
  a {
    background-image: linear-gradient(to bottom right, #F9B54A, #F76B1C);
  }

  @media only screen and (max-width: 576px) {
    .textresize {
      font-size: 0.75rem;
      margin-left: 30%;
      padding: 0.75rem 1.0rem !important;
    }
  }

  @media only screen and (min-width: 577px) and (max-width: 1023px) {
    .textresize {
      margin-left: 13rem;
    }
  }
</style>
